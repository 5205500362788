<template>
  <div>
    <b-card>
      <!-- about -->
      <b-tabs pill>
        <b-tab title="About Me" active>
          <br />
          <!-- Media -->
          <b-media class="mb-2"> </b-media>
          <b-form>
            <b-row>
              <b-col cols="6" md="6">
                <b-form-group label="Social name">
                  <b-form-input v-model="profileData.name" />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group label="Email">
                  <b-form-input v-model="profileData.email" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="12">
                <b-form-group label="My description">
                  <b-form-textarea v-model="profileData.description" />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group label="WebSite">
                  <b-form-input v-model="profileData.site" />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group label="Other links">
                  <b-form-input v-model="profileData.links" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab title="About My Work">
          <b-card-text>
            <h5>Tecnologias em que trabalho</h5>
            <br />
            <b-form>
              <b-row>
                <b-col cols="6" md="6">
                  <b-form-checkbox v-model="profileData.skillJava" value="A">
                    Java
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="profileData.skillSpringBoot"
                    value="A"
                  >
                    Spring Boot
                  </b-form-checkbox>
                  <b-form-checkbox v-model="profileData.skillMongoDb" value="A">
                    MongoDb
                  </b-form-checkbox>
                  <b-form-checkbox v-model="profileData.skillgit" value="A">
                    Git
                  </b-form-checkbox>
                  <b-form-checkbox v-model="profileData.skillvuejs" value="A">
                    Vuejs
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="profileData.skilljavascript"
                    value="A"
                  >
                    Javascript
                  </b-form-checkbox>
                  <b-form-checkbox v-model="profileData.skillcss" value="A">
                    Css
                  </b-form-checkbox>

                  <b-form-checkbox v-model="profileData.skillHtml" value="A">
                    html
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="profileData.skillFirebase"
                    value="A"
                  >
                    html
                  </b-form-checkbox>
                  <b-form-group label="Other languages">
                    <b-form-input v-model="profileData.skillOthers" value="A">
                      html
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
      </b-tabs>

      <div class="buttons d-flex justify-content-end">
        <b-button variant="primary" @click="updateProfile()"> Salvar </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BMedia,
  BForm,
  BFormTextarea,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BForm,
    BTab,
    BFormGroup,
    BFormInput,
    BRow,
    BFormTextarea,
    BMedia,
    BButton,
    BFormCheckbox,
    BCol,
  },

  data() {
    return {
      profileData: {},

      userData: store.state.user.userData,
    };
  },

  methods: {
    updateProfile() {
      axios({
        method: "put",
        url: `${URL_API}profile`,
        headers: getHeader(this.userData),
        data: this.profileData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },
  },
};
</script>
